import BulletPoints from "./bullet-points/bullet-points";
import TripInformationDayDisplay from "./trip-information-day-display/trip-information-day-display";
import TripInformationImageBanner from "./trip-information-image-banner/trip-information-image-banner";
import TripInformationTripName from "./trip-information-trip-name/trip-information-tripname";

import "./trip-information-tab.css";
import useWindowSize from "../../hooks/windowSize";

const TripInformationTab = ({
  day,
  image,
  name,
  content,
  arrowpoints,
  italic,
  fontColor,
}) => {
  const { width } = useWindowSize();
  return (
    <div className="trip-information-tab-main">
      <div className="trip-information-tab">
        {day && <TripInformationDayDisplay day={day} />}
        {image && width < 0 ? <TripInformationImageBanner image={image} /> : ""}
        <TripInformationTripName
          tripName={name}
          italic={italic}
          fontColor={fontColor}
        />
        <div className="trip-information-tab-points">
          {content.map((con, index) => {
            return <BulletPoints key={index} information={con} />;
          })}
          {/* <ArrowPoints /> */}
        </div>
      </div>
    </div>
  );
};

export default TripInformationTab;
