import React from "react";
import "./review-component.css";
import RatingController from "../rating-controller/rating-controller";
import ratingStar from "../../assets/reviews/rating-star.png";

const ReviewComponent = ({ tripImage, tripName, tripreview, rating }) => {
  return (
    <div className="review-component-main">
      <img src={tripImage} alt="tripImage" />

      <div className="review-components-body">
        <div className="review-component-name-main">{tripName}</div>
        <div className="review-components-body-review">
          <div className="review-components-body-review-content">
            {'"' + tripreview + '"'}
          </div>
          <div className="review-component-ratings">
            {Number.isInteger(rating) ? rating + ".0" : rating}
            <div className="rating-image-main">
              <img src={ratingStar} alt="rating" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewComponent;
