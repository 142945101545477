import React, { useState } from "react";
import Navbar from "../../components/navbar/narvbar";

//css
import "./home-mobile.css";
import "./home-desktop.css";

// component
import PhoneCarousal from "../../components/carousal/phone/phone-carousal";

// content
import { HOME_MESSAGE } from "../../content/home-content";
import SocialLinks from "../../components/social-links/social-links";
import useWindowSize from "../../hooks/windowSize";
import NavbarDesktop from "../../components/navbar/desktop/navbar-desktop";

const Home = () => {
  const { width, height } = useWindowSize();
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div id="home">
      {width >= 700 ? <NavbarDesktop /> : <Navbar />}
      {isVisible ? (
        <div className="trip-container-links">
          <SocialLinks />
        </div>
      ) : (
        ""
      )}

      <div className="home-div">
        <PhoneCarousal interval={3000} />
        <div className="home-div-message">{HOME_MESSAGE}</div>
        {/* <div className="home-div-social-link">
          <SocialLinks vertical={true} rotate={true} />
        </div> */}
      </div>
    </div>
  );
};

export default Home;
