import React, { useState, useEffect } from "react";
import "./carousal-adventures.css";
import RatingController from "../rating-controller/rating-controller";
import ratingImage from "../../assets/reviews/rating-star.png";

const CarousalAdventures = ({
  id,
  rating,
  images,
  title,
  alt,
  interval = 1000,
  reviewContent,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => clearInterval(timer);
  }, [images.length, interval]);

  return (
    <div key={id} className="carousal-adv-main">
      <img src={images[currentIndex]} alt={alt} />
      <div id="carousal-adv-name-id" className="carousal-adv-button">
        <div className="carousal-adv-sub">
          {title}
          {reviewContent && <div>{reviewContent}</div>}
        </div>
        <div className="carousal-adv-rating">
          {/* <RatingController rating={rating} /> */}
          <div>{Number.isInteger(rating) ? rating + ".0" : rating}</div>
          <img src={ratingImage} alt="ratingImage" />
        </div>
      </div>
    </div>
  );
};

export default CarousalAdventures;
