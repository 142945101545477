import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import "./hamburger-menu.css";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null); // Ref for the whole menu
  const iconRef = useRef(null); // Ref for the hamburger icon

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    // Close the menu if the click is outside both the menu and icon
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      iconRef.current &&
      !iconRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Add or remove the 'no-scroll' class based on menu open state
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Prevent scrolling
    } else {
      document.body.style.overflow = ""; // Allow scrolling
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = ""; // Cleanup on unmount
    };
  }, [isOpen]);

  return (
    <>
      {/* Hamburger Icon */}
      <div
        className={`menu-icon ${isOpen ? "open" : ""}`}
        onClick={toggleMenu}
        ref={iconRef} // Attach ref to the hamburger icon
      >
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Side Menu */}
      <nav ref={menuRef} className={`side-menu ${isOpen ? "open" : ""}`}>
        <ul>
          <Link
            className="hamburger-link"
            to="/"
            onClick={() => scrollToSection("home")}
          >
            Home
          </Link>
          <Link
            className="hamburger-link"
            to="/"
            onClick={() => scrollToSection("exclusive-package")}
          >
            Packages
          </Link>
          <Link
            className="hamburger-link"
            to="/"
            onClick={() => scrollToSection("about-us")}
          >
            About Us
          </Link>
          <Link
            className="hamburger-link"
            to="/"
            onClick={() => scrollToSection("adventures")}
          >
            Adventures
          </Link>
          <Link
            className="hamburger-link"
            to="/"
            onClick={() => scrollToSection("reviews")}
          >
            Reviews
          </Link>
          <Link className="hamburger-link" to="/connect">
            Connect
          </Link>
        </ul>
      </nav>
    </>
  );
};

export default HamburgerMenu;
