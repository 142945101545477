import React, { useState } from "react";
import "./mobile-carousal.css";

const MobileCarousal = ({ tripName, tripImages, day, night, price }) => {
  const [tripImagesState, setTripImagesState] = useState(tripImages);
  const [showIndex, setShowIndex] = useState(0);

  const handleCarousalClick = (newIndex) => {
    let previousIndex = showIndex; // purana photo
    let newImagesArray = [...tripImagesState];
    newImagesArray[previousIndex] = tripImagesState[newIndex];
    newImagesArray[newIndex] = tripImagesState[previousIndex];
    setTripImagesState(newImagesArray);
  };

  return (
    <div className="mobile-carousal-main">
      <div className="mobile-carousal-main-name">{tripName}</div>
      <div className="mobile-carousal-main-top">
        <img src={tripImagesState[showIndex]} alt="tripImages" />
      </div>
      <div className="mobile-carousal-main-bottom">
        {tripImagesState.map((img, index) => {
          if (index !== 0) {
            return (
              <div key={index} onClick={() => handleCarousalClick(index)}>
                <img src={img} alt="carousal-image" />
              </div>
            );
          }

          return "";
        })}
      </div>
      <div className="mobile-carousal-main-daynight">
        <div>Starts from {price}</div>
        <div>
          {day} Days / {night} Nights
        </div>
      </div>
    </div>
  );
};

export default MobileCarousal;
