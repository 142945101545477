import jibhi1 from "../../assets/exclusive-packages-assets/Jibhi/phone/compressed/jibbhi1.webp";
import jibhi2 from "../../assets/exclusive-packages-assets/Jibhi/phone/compressed/jibbhi2.webp";
import jibhi3 from "../../assets/exclusive-packages-assets/Jibhi/phone/compressed/jibbhi3.webp";
import jibhi4 from "../../assets/exclusive-packages-assets/Jibhi/phone/compressed/jibbhi4.webp";
import jibhi5 from "../../assets/exclusive-packages-assets/Jibhi/phone/compressed/jibbhi5.webp";
import jibhi6 from "../../assets/exclusive-packages-assets/Jibhi/phone/compressed/jibbhi6.webp";
import jibhi7 from "../../assets/exclusive-packages-assets/Jibhi/phone/compressed/jibbhi7.webp";
import jibhi8 from "../../assets/exclusive-packages-assets/Jibhi/phone/compressed/jibbhi8.webp";
import jibhi9 from "../../assets/exclusive-packages-assets/Jibhi/phone/compressed/jibbhi10.webp";

import jibhi_desk1 from "../../assets/exclusive-packages-assets/Jibhi/desktop/compressed/jibhi1.webp";
import jibhi_desk2 from "../../assets/exclusive-packages-assets/Jibhi/desktop/compressed/jibhi2.webp";
import jibhi_desk3 from "../../assets/exclusive-packages-assets/Jibhi/desktop/compressed/jibhi3.webp";
import jibhi_desk4 from "../../assets/exclusive-packages-assets/Jibhi/desktop/compressed/jibhi4.webp";
import jibhi_desk5 from "../../assets/exclusive-packages-assets/Jibhi/desktop/compressed/jibhi5.webp";
import jibhi_desk6 from "../../assets/exclusive-packages-assets/Jibhi/desktop/compressed/jibhi6.webp";
import jibhi_desk7 from "../../assets/exclusive-packages-assets/Jibhi/desktop/compressed/jibhi7.webp";

export const jibhiPhone = [
  jibhi1,
  jibhi2,
  jibhi3,
  jibhi4,
  jibhi5,
  jibhi6,
  jibhi7,
  jibhi8,
  jibhi9,
];

export const jibhiDesktop = [
  jibhi_desk1,
  jibhi_desk2,
  jibhi_desk3,
  jibhi_desk4,
  jibhi_desk5,
  jibhi_desk6,
  jibhi_desk7,
];
