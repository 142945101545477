import React, { useState } from "react";
import "./connect.css";

import emailBackground from "../../assets/email/email-background.png";
import emailBackgroundPhone from "../../assets/email/email-background-mobile.png";
import logo from "../../assets/email/logo.png";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/windowSize";
import {
  SERVER_URL_PRODUCTION,
  SERVER_URL_DEV,
} from "../../content/server_urls";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

const Connect = () => {
  const { width } = useWindowSize();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleOnChange = (e, setMethod) => {
    e.preventDefault();
    setMethod(e.target.value);
  };

  const handleSubmit = async () => {
    if (!email || !userName || !subject || !message) {
      toast.info("Please fill all the input Fields");
      return;
    }

    try {
      const response = await axios.post(`${SERVER_URL_PRODUCTION}/send-email`, {
        userName,
        email,
        subject,
        message,
      });
      toast.success("Email is sent");
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  return (
    <>
      <div className="email-container">
        <img
          src={width <= 600 ? emailBackgroundPhone : emailBackground}
          alt="background-image"
        />
        {width <= 600 ? (
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{
              zIndex: 9999,
              width: "240px",
              position: "absolute",
              left: "20%",
              bottom: "10%",
            }} /* Ensure it's on top */
          />
        ) : (
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{
              zIndex: 9999,
              width: "440px",
            }} /* Ensure it's on top */
          />
        )}

        {width <= 600 ? (
          <div className="email-phone-connect-image">
            <img src={logo} alt="logo" />
          </div>
        ) : (
          ""
        )}

        <div className="email-main">
          {width >= 600 ? (
            <Link to="/" className="email-link-Button ">
              Home
            </Link>
          ) : (
            ""
          )}

          <div className="email-icon-container">
            <img src={logo} alt="logo" />
            <div>Weaving Your Dreams into Unforgettable Adventures</div>
          </div>
          <div className="email-form-container">
            <div className="form-container">
              {width <= 600 ? (
                <div className="email-form-container-heading">
                  Send us a message{" "}
                </div>
              ) : (
                ""
              )}
              <label>Destination</label>
              <input
                type="text"
                value={userName}
                onChange={(e) => handleOnChange(e, setUserName)}
              />
              <label>Mail ID</label>
              <input
                type="email"
                value={email}
                onChange={(e) => handleOnChange(e, setEmail)}
              />

              <label>Subject</label>
              <input
                type="text"
                value={subject}
                onChange={(e) => handleOnChange(e, setSubject)}
              />

              <label>Message</label>
              <textarea
                type="text"
                value={message}
                onChange={(e) => handleOnChange(e, setMessage)}
              />
            </div>
            <button onClick={handleSubmit}>Send Email</button>
            {width <= 600 ? (
              <>
                <div className="headingConnect">
                  Weaving Your Dreams into Unforgettable Adventures
                </div>

                <div className="email-trusted-partners">
                  TRUSTED BY <div>22K+</div> TRAVLLERS
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Connect;
