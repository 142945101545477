import React from "react";
import "./navbar-desktop.css";
import useWindowSize from "../../../hooks/windowSize";

//assets
import popLogo from "../../../assets/navbar/pop-title-logo.png";
import { Link, useNavigate } from "react-router-dom";

const NavbarDesktop = () => {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    section.scrollIntoView({ behavior: "smooth" });
  };

  const { width } = useWindowSize();
  const url = window.location.pathname;
  const navigate = useNavigate();

  return (
    <div className="navbar-desktop-main">
      <div className="navbar-desktop-image">
        <img onClick={() => navigate("/")} src={popLogo} alt="navbar" />
      </div>
      <div className="navbar-desktop-selector">
        <Link
          className="navbar-desktop-link"
          to="/"
          onClick={() => scrollToSection("home")}
        >
          Home
        </Link>
        <Link
          className="navbar-desktop-link"
          to="/"
          onClick={() => scrollToSection("exclusive-package")}
        >
          Packages
        </Link>
        <Link
          className="navbar-desktop-link"
          to="/"
          onClick={() => scrollToSection("about-us")}
        >
          About Us
        </Link>
        <Link
          className="navbar-desktop-link"
          to="/"
          onClick={() => scrollToSection("adventures")}
        >
          Adventures
        </Link>
        <Link
          className="navbar-desktop-link"
          to="/"
          onClick={() => scrollToSection("reviews")}
        >
          Reviews
        </Link>
        <Link className="navbar-desktop-link" to="/connect">
          Connect
        </Link>
      </div>
      )
    </div>
  );
};

export default NavbarDesktop;
