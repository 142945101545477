import React, { useEffect, useState } from "react";
import "./carousal-trip.css";
import { Link } from "react-router-dom";
import useWindowSize from "../../hooks/windowSize";

const CarousalTrip = ({ trip, interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { width } = useWindowSize();

  useEffect(() => {
    const timer = setInterval(() => {
      width >= 1020
        ? setCurrentIndex(
            (prevIndex) => (prevIndex + 1) % trip.desktopImages.length
          )
        : setCurrentIndex(
            (prevIndex) => (prevIndex + 1) % trip.desktopImages.length
          );
    }, interval);

    return () => clearInterval(timer);
  }, [trip.images.length, interval, trip.desktopImages.length]);

  return (
    <div className="carousal-trip-main" key={trip.id}>
      <div className="carousal-trip-visual">{trip.visual}</div>
      <img
        src={
          width >= 1020
            ? trip.desktopImages[currentIndex]
            : trip.desktopImages[currentIndex]
        }
        alt={trip.alt}
      />
      {width >= 1020 ? (
        ""
      ) : (
        <div id="carousal-trip-name-id">{trip.tripName}</div>
      )}
      <div className="carousal-trip-sub">
        {/* Replace here the bottom code */}

        <Link
          id="carousal-trip-link-id"
          className="carousal-button"
          to={`/trip/${trip.id}`}
        >
          {width > 1020 ? `${trip.tripName}` : "Check Out"}
        </Link>
      </div>
    </div>
  );
};

export default CarousalTrip;
