import image1 from "./../assets/adventures/image1.png";
import image2 from "./../assets/adventures/image2.png";
import image3 from "./../assets/adventures/image3.png";

export const ADVENTURE_TITLE = "OUR ADVENTURES";
export const ADVENTURES = [
  {
    id: 1,
    adventureName: "Trekking",
    stars: 4,
    images: [image1],
    alt: "image1",
  },
  {
    id: 2,
    adventureName: "River Rafting",
    stars: 5,
    images: [image3],
    alt: "image2",
  },
  {
    id: 3,
    adventureName: "Psychedelic Parties ",
    stars: 4.5,
    images: [image2],
    alt: "image3",
  },
];
