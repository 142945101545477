// Trip Cover images
import image1 from "../assets/exclusive-packages/trips/image1.png";
import image2 from "../assets/exclusive-packages/trips/image2.png";
import image3 from "../assets/exclusive-packages/trips/image3.png";

//assets route
import tripRoute1 from "../assets/trip-information/trip-route.png";
import { MEMORIES } from "../assets/exclusive-packages-assets/Memories/index";

// Jibhi Tirthan
import {
  jibhiPhone,
  jibhiDesktop,
} from "./exclusive-packages-pictures/jibhi-tirthan";

// kasol
import {
  kasolKheerPhone,
  kasolKheerDesktop,
} from "./exclusive-packages-pictures/kasol-kheer";

// manali
import {
  manaliPhone,
  manaliDesktop,
} from "./exclusive-packages-pictures/manali";

// spiti
import {
  spiti_desktop,
  spiti_winter_desktop,
} from "./exclusive-packages-pictures/spiti_summer";

import { BANNERS } from "../assets/exclusive-packages-assets/Banners/banners";

export const EXCLUSIVE_PACKAGES_TITLE = "EXCLUSIVE PACKAGES";
export const EXCLUSIVE_PACKAGES = [
  {
    id: 1,
    tabImage: "",
    price: "6999",
    visual: "2N/3D",
    day: "3",
    night: "2",
    tripImageBanner: BANNERS[0],
    images: [image1],
    desktopImages: jibhiDesktop,
    tripName: "JIBHI TIRTHAN VALLEY",
    alt: "trip_image1",
    tripImages: jibhiPhone,
    memoryImages: MEMORIES,
    whatsappMessage:
      "Hey!,I want to know about JIBHI TIRTHAN VALLEY. Please provide details",
    hightlights: [
      "Entire travel as per the itinerary. (From Delhi to Manali & Manali to Delhi, it will be in a semi-sleeper Volvo, and for the internal transfer, it will be in a taxi.)",
      "Accommodation in a luxury hotel for 2 nights.",
      "A total of 4 meals will be provided throughout the trip: 3 breakfasts and 1 dinner at the hotel.",
      "Highly skilled trip captains throughout the whole trip. (Not for family or customized departures.)",
    ],
    days: [
      {
        day: 1,
        name: "Reach Aut - Transfer To Jibhi - Hike To Choie Waterfall. Overnight Stay At Jibhi",
        arrow: true,
        tripRoute: tripRoute1,
        content: [
          "Reach Out by morning.",
          "Local transfer from Auto Jibhi",
          "Freshen-up and relax at the hotel & have your breakfast",
          "Hike to Choie Waterfall.",
          "Go for Cafe hopping & Come back to the hotel by evening.",
          "Bonfire, Dinner and overnight at the hotel.",
        ],
      },
      {
        day: 2,
        name: "Visit Jalori Pass - Trek To 360 Viewpoint And Serolsar Lake - Overnight Stay At Jibhi",
        arrow: false,
        content: [
          "Wake up & post breakfast go for the serolsarlake trek.",
          "Drive to Jalori Pass,trek further and enjoy a stunning 360° view of the Great Himalayas.",
          "Witness awe-inspiring beauty.",
          "Come back to the hotel and enjoy music",
          "Dinner and overnight stay.",
        ],
      },
      {
        day: 3,
        name: "Visit Jibhi Waterfall - Evening Departure To Delhi",
        arrow: false,
        content: [
          "Wake up early morning to see a beautiful sunrise and after have breakfast.",
          "Post breakfast check outfrom the hotel.",
          "Enjoy Hike to Jibhi Waterfall and Cafe Hopping.",
          "Departure back to Delhi.",
          "Reach Delhi by morning 6 A.M.",
        ],
      },
      {
        day: 4,
        name: "Delhi Arrival | Trip Ends",
        arrow: false,
        content: [
          " We will arrive in Delhi early in the morning.",
          "It is time to bid farewell to your fellow travelers and the remarkable journey.",
          "The adventure comes to an end.",
        ],
      },
    ],
  },
  {
    id: 2,
    tabImage: "",
    visual: "2N/3D",
    day: "3",
    night: "2",
    price: "6999",
    tripImageBanner: BANNERS[1],
    images: [image3],
    desktopImages: manaliDesktop,
    tripName: "MANALI SISSU",
    alt: "trip_image1",
    tripImages: manaliPhone,
    memoryImages: MEMORIES,
    whatsappMessage:
      "Hey !, I want to know about MANALI SISSU trip. Please provide details",
    hightlights: [
      "Entire travel as per the itinerary. (From Delhi to Manali & Manali to Delhi, it will be in a semi-sleeper Volvo, and for the internal transfer, it will be in a taxi.)",
      "Accommodation in a luxury hotel for 2 nights.",
      "A total of 4 meals will be provided throughout the trip: 3 breakfasts and 1 dinner at the hotel.",
      "Highly skilled trip captains throughout the whole trip. (Not for family or customized departures.)",
    ],
    days: [
      {
        day: 1,
        name: "Reach Manali - jogini falls-overnight stay at manali",
        arrow: true,
        tripRoute: tripRoute1,
        content: [
          "Check in to the hotel, freshen up & have breakfast orlunch (Dependingon the Arrival)",
          "Afterrelaxing, head to Vashishth village, and begin the trek to the stunning Jogini Falls",
          "Come back to the hotel,relax for a while.",
          "In the Evening, You are free to explore some local part of Manali – MallRoad. (At your own).",
          "Overnight stay in hotel.",
          "Sleep overnightin the Hotel and have an experience of a lifetime.",
        ],
      },
      {
        day: 2,
        name: "Reach Manali- Visit Sissu solang- Overnight stay at Manali",
        arrow: false,
        content: [
          "After having Breakfast at hotel.",
          "Proceed forfull day tourto Solang valley, Atal Tunnel & Sissu.",
          "Experience the solang valley zip line adventure & paragliding. ( At your own).",
          "Return to the Hotel ,enjoy your dinner & bone fire",
        ],
      },
      {
        day: 3,
        name: "Manali sightseeing-Departure to delhi",
        arrow: false,
        content: [
          "Wake up early morning to see a beautiful sunrise and after have breakfast.",
          "Check outthe hotelthen proceed for sightseeing of Hadimba Temple Tibetan, monastery and The Old Manali.",
          "Aftertaking lifetime experience of Manali & in the evening we will departfor Delhi.",
          "Reach Delhi by morning 6 A.M",
        ],
      },
    ],
  },
  {
    id: 3,
    tabImage: "",
    visual: "2N/3D",
    day: "3",
    night: "2",
    price: "6499",
    tripImageBanner: BANNERS[2],
    images: [image3],
    desktopImages: kasolKheerDesktop,
    tripName: "KASOL KHEERGANGA",
    alt: "trip_image1",
    tripImages: kasolKheerPhone,
    memoryImages: MEMORIES,
    whatsappMessage:
      "Hey !, I want to know about KASOL KHEERGANGA. Please provide details",
    hightlights: [
      "Entire travel as per the itinerary. (From Delhi to Kasol & Kasol to Delhi, it will be in a semi-sleeper Volvo, and for the internal transfer, it will be in Sumos only.)",
      "Accommodation in a hotel at Kasol for 1 night & in camps at Kheerganga for 1 night.",
      "A total of 4 meals will be provided throughout the trip - 1 meal on Day 1 (Breakfast) + 2 meals on Day 2 (Breakfast and Dinner) + 1 meal on Day 3 (Breakfast).",
      "Highly skilled trip captains throughout the whole trip. (Not for family or customized departures.)",
    ],
    days: [
      {
        day: 1,
        name: "Reach Kasol- Visit Chalal Village- Overnight stay at Kasol",
        arrow: true,
        tripRoute: tripRoute1,
        content: [
          "Start from Delhi by 6 PM. Reach Kasol next day morning",
          "Reach Kasol by morning.",
          "After having breakfast and some leisure, you can explore the Kasolmarket, and try different cuisines at the local Hippie Cafe's ojust relax by the gushing Parvati river",
          "In the evening, go for a hike to Chalal village and capture some beautiful views of Parvati Valley",
          "Chill at the Cafe's in Kasol and return to the Hotel for Bonfire & sleep overnight.",
        ],
      },
      {
        day: 2,
        name: "Trek to Kheerganga. Overnight camping at Kheerganga",
        arrow: false,
        content: [
          "Wake up early morning and after having breakfast, head for Kheerganga.",
          "Trek will start from a village called Barsheni (22 km from Kasol) anyou will be transferred to Barsheni in private vehicles.",
          "The Trek Trail of Kheerganga is 10 kms with a moderate difficulty level.",
          "Enjoy your walk in the heart of the beautiful Parvati valley with a stunning view of snowcapped mountains all around.",
          "Have your dinner at bonfire under the sky studded with stars and snowcapped mountains all around.",
          "Sleep overnight in the camps and have an experience of a lifetime.",
        ],
      },
      {
        day: 3,
        name: "Trek down to Barsheni. Departure to Delhi.",
        arrow: false,
        content: [
          "Wake up early morning to see a beautiful sunrise and after havingbreakfast head back to Kasol. ",
          "Trek your way down to Barsheni and reach Kasol by evening.",
          "On the way, visit the holy Manikaran Gurudwara, famous for its natural hotsprings",
          "Head back to Delhi with lots of amazing trip memories and reach bthe next day morning.",
        ],
      },
    ],
  },
  {
    id: 4,
    tabImage: "",
    price: "16999",
    visual: "8N/9D",
    day: "9",
    night: "8",
    tripImageBanner: BANNERS[3],
    images: [image2],
    desktopImages: spiti_desktop,
    tripName: "SPITI VALLEY BACKPACKING TRIP",
    alt: "trip_image1",
    tripImages: spiti_desktop,
    memoryImages: MEMORIES,
    whatsappMessage:
      "Hey !, I want to know about SPITI VALLEY BACKPACKING TRIP. Please provide details",
    hightlights: [
      "Transportation: AC Volvo Bus - Delhi to Shimla and back. Tempo Traveller - Comfortable journey in a Tempo Traveller from Shimla to Manali.",
      "Accommodations: Shared accommodations as per the itinerary for a communal experience.",
      "Meal Plan - MAP Plan: Total 12 meals included - 6 breakfasts and 6 dinners.",
      "Experienced Trip Lead: The trip lead will be present throughout the tour, ensuring a guided experience.",
      "Driver Allowances & Night Charges: All allowances and charges for the driver are covered.",
      "Mechanical Support: Assistance with any mechanical issues during the journey.",
      "Permits Required: Necessary permits for restricted areas are taken care of.",
      "First Aid Kits: Well-equipped first aid kits for any medical emergencies.",
      "Oxygen Cylinders: Provision of oxygen cylinders for high-altitude areas.",
      "Oximeter: Availability of oximeters to monitor oxygen levels.",
    ],
    days: [
      {
        day: 1,
        name: "Delhi to Shimla | Overnight Journey",
        arrow: true,
        tripRoute: tripRoute1,
        content: [
          "We'll all assemble at the pickup point around 9 PM, a gathering of fellow adventure seekers ready for this captivating journey.",
          "Our team captains will meet and greet us, providing an overview of the exciting days ahead, creating a sense of unity.",
          "Together, we'll commence the overnight journey to Shimla, setting the stage for shared experiences and newfound friendships.",
        ],
      },
      {
        day: 2,
        name: "Shimla to Chitkul ",
        arrow: false,
        content: [
          "Upon arrival in Shimla, the group will embark on a scenic drive to Chitkul, witnessing the unfolding beauty as one.",
          "Check-in at our designated stay, a collective pause to appreciate the surroundings & bond over the enchantment of Chitkul's mountains. ",
        ],
      },
      {
        day: 3,
        name: "Chitkul to Tabo via Khab Sangam, Nako, day and Gue Monastery",
        arrow: false,
        content: [
          "Awaken to the promise of exploration as we traverse through Khab Sangam, Nako, and Gue Monastery.",
          "Our journey to Tabo unfolds, each milestone deepening the shared adventure and connection within the group.",
        ],
      },
      {
        day: 4,
        name: "Tabo to Kaza via Dhankar Monastery and day Pin Valley",
        arrow: false,
        content: [
          "Following a hearty breakfast, we head towards Kaza, pausing at Dhankar Monastery and exploring Pin Valley.",
          "The day's adventures culminate as we reach Kaza, where our designated stay awaits, offering a chance to unwind and share tales.",
        ],
      },
      {
        day: 5,
        name: "Spiti Sightseeing Day - Hikkim, Komic, day Langza, Key Monastery",
        arrow: false,
        content: [
          "Our group explores Spiti's gems together, unveiling the mysteries of Hikkim,Komic, Langza. Our exploration then takes us to Key Monastery, and Kibber.",
          "The spiritual and cultural immersion becomes a shared experience, fostering memories that bind us as a group.",
          "We return to Kaza, the tranquil evening and overnight stay filled with shared reflections on our Spiti sojourn",
        ],
      },
      {
        day: 6,
        name: "Kaza to Chandratal via Chicham Bridge",
        arrow: false,
        content: [
          "With collective anticipation, we embark on the journey to Chandratal, passing through the iconic Chicham Bridge.",
          "Witnessing the changing landscapes, we arrive at the pristine Chandratal Lake, where our campsites become a shared haven under the starlit Spiti sky.",
        ],
      },
      {
        day: 7,
        name: "Chandratal to Manali",
        arrow: false,
        content: [
          " After bidding farewell to Chandratal, we drive collectively toward the vibrant town of Manali.",
          "The transition from the tranquil Spiti Valley to the lively ambiance of Manali is felt as one.",
          "Checkin at our designated stay, marking the end of another day filled with shared experiences.",
        ],
      },
      {
        day: 8,
        name: "Manali Leisure Day",
        arrow: false,
        content: [
          "Today is a leisure day in Manali, allowing each traveler the freedom to explore local markets, cafes, and the lively Mall Road.",
          "The day becomes a canvas for personalization, creating space for adventure or relaxation, shared or individual.",
        ],
      },
      {
        day: 9,
        name: "Delhi Arrival | Trip Ends ",
        arrow: false,
        content: [
          "We board the overnight bus from Manali to Delhi, carrying reflections on the profound Spiti Valley experience.",
          "Upon arrival in Delhi, we part ways with newfound friends, each of us carrying the essence of Spiti Valley in our collective hearts.",
        ],
      },
    ],
  },
  {
    id: 5,
    tabImage: "",
    visual: "8N/9D",
    day: "9",
    night: "8",
    price: "16999",
    tripImageBanner: BANNERS[4],
    images: [image2],
    desktopImages: spiti_winter_desktop,
    tripName: "WINTER WONDERS OF SPITI VALLEY",
    alt: "trip_image1",
    tripImages: spiti_winter_desktop,
    memoryImages: MEMORIES,
    whatsappMessage:
      "Hey !, I want to know about WINTER WONDERS OF SPITI VALLEY. Please provide details",
    hightlights: [
      "Transportation: AC Volvo Bus - Delhi to Shimla and back. Tempo Traveller - Comfortable journey in a Tempo Traveller from Shimla to Manali.",
      "Accommodations: Shared accommodations as per the itinerary for a communal experience.",
      "Meal Plan - MAP Plan: Total 12 meals included - 6 breakfasts and 6 dinners.",
      "Experienced Trip Lead: The trip lead will be present throughout the tour, ensuring a guided experience.",
      "Driver Allowances & Night Charges: All allowances and charges for the driver are covered.",
      "Mechanical Support: Assistance with any mechanical issues during the journey.",
      "Permits Required: Necessary permits for restricted areas are taken care of.",
      "First Aid Kits: Well-equipped first aid kits for any medical emergencies.",
      "Oxygen Cylinders: Provision of oxygen cylinders for high-altitude areas.",
      "Oximeter: Availability of oximeters to monitor oxygen levels.",
    ],
    days: [
      {
        day: 1,
        name: "Delhi to Shimla | Overnight Journey",
        arrow: true,
        tripRoute: tripRoute1,
        content: [
          "We, the group, will gather at the pick-up point at 9:00 PM.",
          "A warm meeting with our trip leader kicks off our adventure, as they provide essential trip details.",
          "Following the briefing, we embark on an overnight journey to Shimla.",
          "Our journey begins from Majnu Ka Tila.",
        ],
      },
      {
        day: 2,
        name: "Shimla to Chitkul ",
        arrow: false,
        content: [
          "Today, we set out towards Sangla/Chitkul, famously known as the last village of India.",
          "En route, we traverse numerous underpasses, treating ourselves to breathtaking vistas of mountains, valleys, and lush meadows.",
          "Upon reaching Chitkul, we check into our respective rooms or camps.",
          "Dinner is a delightful affair, followed by a restful over night stay in Chitkul.",
        ],
      },
      {
        day: 3,
        name: "Sangla/Chitkul to Nako",
        arrow: false,
        content: [
          "• After a satisfying breakfast, we continue our journey to Nako, renowned for its Star Lake.",
          "Weather permitting, we may be rewarded with glimpses of Chitkul Peak along the way.",
          "Post-check-in, a sumptuous dinner is served, and we enjoy a peaceful overnight stay in Nako.",
        ],
      },
      {
        day: 4,
        name: "Nako to Kaza Via Gue Monastery and day Dhankar Monastery",
        arrow: false,
        content: [
          "• With the first light of day, we head to Kaza, the head quarters of Spiti.",
          "En route to Dhankar, we pass through Malling Nala and make a reverent stop at the Nako Monastery.",
          "Our journey includes a stop at the Gue Monastery to witness the intriguing Monk mummy.",
          "We then proceed to the ancient Dhankar Monastery, one of the oldest in the region.",
          "Later, we check in to our cozy hotel in Kaza, where a hearty dinner awaits us.",
        ],
      },
      {
        day: 5,
        name: "Kaza Sightseeing Day",
        arrow: false,
        content: [
          "Post breakfast, we embark on a day of sightseeing.",
          "Our itinerary includes visits to Key Monastery, the Chicham Bridge (Asia’s Highest Bridge), and Kibber Village, known as the second home for Snow leopards.",
          "We also explore Hikkim, home to the world’s highest post office.",
          "Our journey takes us to Komic village, one of the world’s highest villages, and Langza Village, where the iconic Buddha statue stands tall.",
          "Finally, we return to Kaza, where we enjoy a delicious dinner and a comfortable overnight stay.",
        ],
      },
      {
        day: 6,
        name: "Kaza to Tabo Monastery via Pin Valley",
        arrow: false,
        content: [
          "Early morning breakfast fuels our journey to Pin Valley, known for its color-changing mountains and the most enchanting vibes reminiscent of winter in Game of Thrones.",
          "We reach Tabo Monastery by evening, famous for its meditation caves.",
          "After check-in, a delightful dinner is served, followed by a peaceful overnight stay in Tabo.",
        ],
      },
      {
        day: 7,
        name: "Tabo to Kalpa via Khab Sangam",
        arrow: false,
        content: [
          "The day begins with a morning breakfast before we commence our return journey to Kalpa.",
          "Kalpa is renowned for its awe-inspiring views of Kinner Kailash and the famous Suicide Point cliff.",
          "By evening, we reach Kalpa, where we stay amidst apple orchards and enjoy a hearty dinner.",
        ],
      },
      {
        day: 8,
        name: "Kalpa to Shimla and Bus to Delhi",
        arrow: false,
        content: [
          "Our day begins with breakfast before we set out on our return journey.",
          "We head back to Shimla.",
          "In the evening, we board an overnight bus from Shimla to Delhi.",
        ],
      },
      {
        day: 9,
        name: "Delhi Arrival | Tour Ends",
        arrow: false,
        content: [
          "The group arrives in Delhi early in the morning, and it’s time to bid farewell.",
          "With fond memories, we part ways, marking the end of our memorable journey.",
        ],
      },
    ],
  },
];
