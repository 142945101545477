import React from "react";
import "./memories.css";

const Memories = ({ images }) => {
  return (
    <div className="memories-container">
      <div className="memories-container-sub">
        <div className="memories-main">
          {images.map((image, index) => (
            <img key={index} src={image} alt={`memoriesImage ${index + 1}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Memories;
