import React from "react";

//css
import "./reviews.css";

//content
import { REVIEWS_TITLE, REVIEWS_CONTENT } from "../../content/reviews";
import ReviewComponent from "../../components/review-component/review-component";

const Reviews = () => {
  return (
    <div id="reviews">
      <div className="adventures-header-holder">
        <div className="reviews-h2">{REVIEWS_TITLE}</div>
        <div>
          ""Real experiences, genuine reviews – see what our travelers have to
          say!""
        </div>
      </div>
      <div className="reviews-main">
        {REVIEWS_CONTENT.map((rev) => (
          <ReviewComponent
            key={rev.id}
            tripImage={rev.image}
            tripName={rev.trip}
            tripreview={rev.review}
            rating={rev.stars}
          />
        ))}
      </div>
    </div>
  );
};

export default Reviews;
