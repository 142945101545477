import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/home";
import AboutUs from "./pages/about-us/about-us";
import Reviews from "./pages/reviews/reviews";
import ExclusivePackage from "./pages/packages/exclusive-package";
import TrustedBanner from "./pages/trusted-banner/trusted-banner";
import Adventures from "./pages/adventures/adventures";
import Footer from "./pages/footer/footer";
import Trip from "./pages/trip/trip";
import TopNavigatorComponent from "./components/top-navigator-component/top-navigator-component";
import Connect from "./pages/connect/connect";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/trip/:tripId" element={<Trip />} />
        <Route path="/connect" element={<Connect />} />
        <Route
          path="/"
          element={
            <div className="App">
              <TopNavigatorComponent />
              <Home />
              <ExclusivePackage />
              <AboutUs />
              <TrustedBanner />
              <Adventures />
              <Reviews />
              <Footer />
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
