// css
import "./trip.css";

// packages
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useWindowSize from "../../hooks/windowSize";
import banner from "../../assets/trips/memories/banner.png";

// contents
import { EXCLUSIVE_PACKAGES } from "../../content/exclusive-package";

// components
import Navbar from "../../components/navbar/narvbar";
import TripInformationTab from "../../components/trip-information-tab/trip-information-tab";
import Memories from "../../components/memories/memories";
import Footer from "../footer/footer";
import MobileCarousal from "../../components/mobile-carousal/mobile-carousal";
import NavbarDesktop from "../../components/navbar/desktop/navbar-desktop";
import DesktopCarousal from "../../components/desktop-carousal/desktop-carousal";
import TopNavigatorComponent from "../../components/top-navigator-component/top-navigator-component";
import SocialLinks from "../../components/social-links/social-links";

const Trip = () => {
  const { tripId } = useParams();
  const trip = EXCLUSIVE_PACKAGES.find((trip) => trip.id === parseInt(tripId));
  const { width } = useWindowSize();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });

    const handleScroll = () => {
      if (window.scrollY > 1800) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="trip-container">
      {isVisible ? (
        <div className="trip-container-links">
          <SocialLinks whatsappMessage={trip.whatsappMessage} />
        </div>
      ) : (
        ""
      )}
      {width <= 700 ? <Navbar backButton={true} /> : <NavbarDesktop />}

      <TopNavigatorComponent />
      <div className="trip-container-carousal-container">
        {width <= 700 ? (
          <MobileCarousal
            tripImages={trip.tripImages}
            day={trip.day}
            night={trip.night}
            price={trip.price}
            tripName={trip.tripName}
          />
        ) : (
          <DesktopCarousal
            name={trip.tripName}
            images={trip.desktopImages}
            day={trip.day}
            nights={trip.night}
            price={trip.price}
          />
        )}
      </div>
      <div className="trip-information-container-image">
        <img src={trip.tripImageBanner} alt="tripBanner" />
      </div>
      <div className="trips-information-container-main">
        <div className="trips-information-container">
          {trip.days.map((day, index) => {
            return (
              <TripInformationTab
                key={index}
                day={day.day}
                image={day.tripRoute}
                name={day.name}
                content={day.content}
                arrowpoints={day.arrow}
              />
            );
          })}
        </div>
      </div>
      <div className="trips-highlights-information-container-main">
        <div className="trips-highlights-information-container">
          <TripInformationTab
            content={trip.hightlights}
            name="Highlights"
            fontColor="red"
            italic="italic"
          />
        </div>
      </div>
      <img className="memories-banner" src={banner} alt="memories-banner" />
      <Memories images={trip.memoryImages} />
      <Footer whatsappMessage={trip.whatsappMessage} />
    </div>
  );
};

export default Trip;
