import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./social-links.css";

// assets
import insta from "../../assets/home/social-links-icons/instagram_color.png";
import email from "../../assets/home/social-links-icons/email_color.png";
import whats from "../../assets/home/social-links-icons/whatsapp_color.png";

import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";

// content
import { INSTA_LINK, EMAIL, WHATSAPP_LINK } from "../../content/home-content";

// components
import useWindowSize from "../../hooks/windowSize";

const SocialLinks = ({ rotate = false, whatsappMessage, footer = false }) => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const encodedMessage = encodeURIComponent(whatsappMessage);

  return (
    <div
      style={rotate ? { transform: "rotate(90deg)" } : { transform: "none" }}
      className="social-links-main"
    >
      {width < 400 ? (
        <FaInstagram
          onClick={() => window.open(INSTA_LINK, "_blank")}
          style={{
            fontSize: `${footer ? "28px" : "24px"}`,
            color: `${footer ? "" : "black"}`,
          }}
        />
      ) : (
        <FaInstagram
          onClick={() => window.open(INSTA_LINK, "_blank")}
          style={{
            fontSize: `${footer ? "48px" : "24px"}`,
            color: `${footer ? "" : "black"}`,
          }}
        />
      )}

      {width < 400 ? (
        <FaWhatsapp
          onClick={() => window.open(WHATSAPP_LINK + encodedMessage, "_blank")}
          style={{
            fontSize: `${footer ? "28px" : "24px"}`,
            color: `${footer ? "" : "black"}`,
          }}
        />
      ) : (
        <FaWhatsapp
          onClick={() => window.open(WHATSAPP_LINK + encodedMessage, "_blank")}
          style={{
            fontSize: `${footer ? "48px" : "24px"}`,
            color: `${footer ? "" : "black"}`,
          }}
        />
      )}

      {width < 400 ? (
        <MdOutlineMailOutline
          onClick={() => navigate("/connect")}
          style={{
            fontSize: `${footer ? "28px" : "24px"}`,
            color: `${footer ? "" : "black"}`,
          }}
        />
      ) : (
        <MdOutlineMailOutline
          onClick={() => navigate("/connect")}
          style={{
            fontSize: `${footer ? "48px" : "24px"}`,
            color: `${footer ? "" : "black"}`,
          }}
        />
      )}
    </div>
  );
};

export default SocialLinks;
