import React, { useState } from "react";
import "./desktop-carousal.css";

import right from "../../assets/controls/right.png";
import left from "../../assets/controls/left.png";

import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

const DesktopCarousel = ({ name = "UNKNOWN", images, day, nights, price }) => {
  const [currentImage, setCurrentImage] = useState(images[0]);
  const [startIndex, setStartIndex] = useState(1);

  const handleClick = (index) => {
    if (index < 0) {
      index = 0;
    }

    if (index >= images.length - 1) {
      index = images.length - 2;
    }
    setStartIndex(index);
  };

  const getImages = () => {
    const filteredImages = images.slice(startIndex, startIndex + 2);
    return filteredImages;
  };

  const handleImageSelect = (imgRecieved) => {
    const image = images.find((img) => img === imgRecieved);
    setCurrentImage(image);
  };

  return (
    <div className="desktop-trip-page-carousal-main">
      <div className="desktop-trip-page-carousal-name-display">{name}</div>
      <div className="desktop-trip-carousal-top">
        <img src={currentImage} alt="image-current" />
      </div>
      <div className="desktop-carousal-bottom-container">
        <div className="desktop-carousal-indexing">
          <div
            className="arrow-controls"
            onClick={() => handleClick(startIndex - 1)}
          >
            <FaChevronLeft style={{ fontSize: "40px", color: "white" }} />
          </div>
          <div className="carousal-container">
            {getImages().map((img) => {
              return (
                <div
                  className="carousal-bottom-image-container"
                  onClick={() => handleImageSelect(img)}
                >
                  <img src={img} alt="carousalImage" />
                </div>
              );
            })}
          </div>
          <div
            className="arrow-controls"
            onClick={() => handleClick(startIndex + 1)}
          >
            <FaChevronRight style={{ fontSize: "40px", color: "white" }} />
          </div>
        </div>
        <div className="desktop-carousal-information">
          <div className="desktop-carousal-information-pref">
            Package Starts From
          </div>
          <div className="desktop-carousal-information-tab">
            {nights} NIGHTS / {day} DAYS
          </div>
          <div className="desktop-carousal-information-tab">
            Starts From {price} /-
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopCarousel;
