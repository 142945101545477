export const MESSAGE = "Trusted By";
export const NUMBER = "22K+";
export const SUB_MESSAGE = "Travellers";

export const TRUSTED_BANNER_CONTENT = [
  {
    id: 1,
    message: "Trusted By",
    number: "22K+",
    subMessage: "Travellers",
  },
  {
    id: 2,
    number: "9100+",
    subMessage: "Satisfied Travellers",
  },
  {
    id: 3,
    number: "850+",
    subMessage: "Successful Trips",
  },
  {
    id: 4,
    number: "4.5+",
    subMessage: "Rating",
  },
];
