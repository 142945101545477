import image1 from "../assets/reviews/image1.png";
import image2 from "../assets/reviews/image2.png";
import image3 from "../assets/reviews/image3.png";

export const REVIEWS_TITLE = "REVIEWS";
export const REVIEWS_CONTENT = [
  {
    id: 1,
    review:
      "It was a wonderful journey and enjoyed a lot and welcome gesture was good and the guides were good",
    trip: "Kasol Trip and Trek to Kheerganga",
    image: [image1],
    stars: 4.5,
    alt: "image1",
  },
  {
    id: 2,
    review:
      "It was a wonderful journey and enjoyed a lot and welcome gesture was good and the guides were good",
    trip: "Kasol Trip and Trek to Kheerganga",
    image: [image2],
    stars: 4.0,
    alt: "image2",
  },
  {
    id: 3,
    review:
      "It was a wonderful journey and enjoyed a lot and welcome gesture was good and the guides were good",
    trip: "Kasol Trip and Trek to Kheerganga",
    image: [image3],
    stars: 5,
    alt: "image3",
  },
];
