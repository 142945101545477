import { MdExposurePlus1 } from "react-icons/md";
import spiti_desk1 from "../../assets/exclusive-packages-assets/spiti/desktop/spiti1.jpg";
import spiti_desk2 from "../../assets/exclusive-packages-assets/spiti/desktop/spiti2.png";
import spiti_desk3 from "../../assets/exclusive-packages-assets/spiti/desktop/spiti3.jpg";
import spiti_desk4 from "../../assets/exclusive-packages-assets/spiti/desktop/spiti4.webp";
import spiti_desk5 from "../../assets/exclusive-packages-assets/spiti/desktop/spiti5.jpg";

import spiti_winter1 from "../../assets/exclusive-packages-assets/spiti-winter/desktop/spiti_winter1.webp";
import spiti_winter2 from "../../assets/exclusive-packages-assets/spiti-winter/desktop/spiti_winter2.webp";
import spiti_winter3 from "../../assets/exclusive-packages-assets/spiti-winter/desktop/spiti_winter3.webp";
import spiti_winter4 from "../../assets/exclusive-packages-assets/spiti-winter/desktop/spiti_winter4.webp";
import spiti_winter5 from "../../assets/exclusive-packages-assets/spiti-winter/desktop/spiti_winter5.webp";

export const spiti_phone = [];
export const spiti_desktop = [
  spiti_desk1,
  spiti_desk2,
  spiti_desk3,
  spiti_desk4,
  spiti_desk5,
];

export const spiti_winter_desktop = [
  spiti_winter1,
  spiti_winter2,
  spiti_winter3,
  spiti_winter4,
  spiti_winter5,
];
