import React from "react";
import "./trip-information-image-banner.css";

const TripInformationImageBanner = ({ image }) => {
  return (
    <div className="trip-information-image-banner-main">
      <div className="trip-information-image-banner">
        <img src={image} alt="trip-image-banner" />
      </div>
    </div>
  );
};

export default TripInformationImageBanner;
