import React from "react";

import "./content-banner.css";

const ContentBanner = ({
  backgroundColor,
  content,
  fontColor = "white",
  boxShadow,
  visible,
}) => {
  return (
    visible && (
      <div
        className="content-banner-main"
        style={{
          color: `${fontColor}`,
          background: `${backgroundColor}`,
          boxShadow: `${boxShadow}`,
        }}
      >
        <div
          id="content-banner-main-content"
          className="content-banner-main-content"
        >
          {content.split(":")[0]}
        </div>
        <div className="content-banner-main-content">
          {content.split(":")[1]}
        </div>
      </div>
    )
  );
};

export default ContentBanner;
