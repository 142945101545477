import React from "react";
import "./trip-information-tripname.css";

const TripInformationTripName = ({ tripName, italic, fontColor }) => {
  return (
    <div
      style={{ color: `${fontColor}`, fontStyle: `${italic}` }}
      className="trip-information-trip-name-main"
    >
      {tripName}
    </div>
  );
};

export default TripInformationTripName;
