import React, { useRef } from "react";
import useWindowSize from "../../hooks/windowSize";

//css
import "./exclusive-package.css";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaArrowAltCircleLeft } from "react-icons/fa";

// content
import {
  EXCLUSIVE_PACKAGES_TITLE,
  EXCLUSIVE_PACKAGES,
} from "../../content/exclusive-package";
import CarousalTrip from "../../components/carousal-trip/carousal-trip";

const ExclusivePackage = () => {
  // Ref for scrolling container
  const scrollContainerRef = useRef(null);
  const { width } = useWindowSize();

  // Function to scroll left
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -300, behavior: "smooth" });
    }
  };

  // Function to scroll right
  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
    }
  };

  return (
    <div id="exclusive-package">
      <div className="exclusive-package-header-holder">
        <div className="exclusive-package-h2">{EXCLUSIVE_PACKAGES_TITLE}</div>
        <div className="exclusive-package-h1">
          "Discover handpicked exclusive packages and tailor-made trips for
          unforgettable travel experiences."
        </div>
      </div>

      {/* Scroll control buttons */}

      {/* Scrollable container for packages */}
      <div className="exclusive-package-trip-main" ref={scrollContainerRef}>
        {EXCLUSIVE_PACKAGES.map((trip) => (
          <CarousalTrip key={trip.id} trip={trip} />
        ))}
      </div>

      {width >= 600 ? (
        <div className="button-carousal-phone">
          <FaArrowAltCircleLeft
            onClick={scrollLeft}
            style={{ fontSize: "30px", color: "white", marginLeft: "20px" }}
          />
          <FaArrowAltCircleRight
            onClick={scrollRight}
            style={{ fontSize: "30px", color: "white", marginLeft: "20px" }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ExclusivePackage;
