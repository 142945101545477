import React from "react";

// content
import { ADVENTURE_TITLE, ADVENTURES } from "../../content/adventures";

import "./adventures.css";
import CarousalAdventures from "../../components/carousal-adventures/carousal-adventures";

const Adventures = () => {
  return (
    <div id="adventures">
      <div className="adventures-header-holder">
        <div className="adventures-h2">{ADVENTURE_TITLE}</div>
        <div>
          "Embark on thrilling journeys with *Our Adventures*, crafted for
          unforgettable experiences."
        </div>
      </div>
      <div className="adventures-main">
        {ADVENTURES.map((adv) => (
          <CarousalAdventures
            key={adv.id}
            title={adv.adventureName}
            images={adv.images}
            rating={adv.stars}
            alt={adv.alt}
          />
        ))}
      </div>
    </div>
  );
};

export default Adventures;
