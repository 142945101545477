import kasol1 from "../../assets/exclusive-packages-assets/kasol-kheer/phone/compressed/kasol1.webp";
import kasol2 from "../../assets/exclusive-packages-assets/kasol-kheer/phone/compressed/kasol2.webp";
import kasol3 from "../../assets/exclusive-packages-assets/kasol-kheer/phone/compressed/kasol3.webp";
import kasol4 from "../../assets/exclusive-packages-assets/kasol-kheer/phone/compressed/kasol4.webp";
import kasol5 from "../../assets/exclusive-packages-assets/kasol-kheer/phone/compressed/kasol5.webp";
import kasol6 from "../../assets/exclusive-packages-assets/kasol-kheer/phone/compressed/kasol6.webp";
import kasol7 from "../../assets/exclusive-packages-assets/kasol-kheer/phone/compressed/kasol7.webp";
import kasol8 from "../../assets/exclusive-packages-assets/kasol-kheer/phone/compressed/kasol8.webp";
import kasol9 from "../../assets/exclusive-packages-assets/kasol-kheer/phone/compressed/kasol9.webp";

import kasol_desk1 from "../../assets/exclusive-packages-assets/kasol-kheer/desktop/original/kasol1.webp";
import kasol_desk2 from "../../assets/exclusive-packages-assets/kasol-kheer/desktop/original/kasol2.webp";
import kasol_desk3 from "../../assets/exclusive-packages-assets/kasol-kheer/desktop/original/kasol3.webp";
import kasol_desk4 from "../../assets/exclusive-packages-assets/kasol-kheer/desktop/original/kasol4.webp";
import kasol_desk5 from "../../assets/exclusive-packages-assets/kasol-kheer/desktop/original/kasol5.webp";

export const kasolKheerPhone = [
  kasol1,
  kasol2,
  kasol3,
  kasol4,
  kasol5,
  kasol6,
  kasol7,
  kasol8,
  kasol9,
];
export const kasolKheerDesktop = [
  kasol_desk1,
  kasol_desk2,
  kasol_desk3,
  kasol_desk4,
  kasol_desk5,
];
