import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

//assets
import popLogo from "../../assets/navbar/pop-title-logo.png";

//css
import "./navbar.css";
import HamburgerMenu from "../hamburger-menu/hamburger-menu";
import BackButton from "../back-button/back-button";

const Navbar = ({ backButton }) => {
  const { pathName } = useLocation();
  const navigate = useNavigate();

  const backToHome = () => {
    navigate("/");
  };

  return (
    <div className="navbar-main">
      <img src={popLogo} alt="navbar" onClick={() => navigate("/")} />
      {backButton ? (
        <div className="navbar-button-control">
          <BackButton backArrowMark handleBackButton={backToHome} />
        </div>
      ) : (
        <HamburgerMenu />
      )}

      {/* <div className="navbar-back-arrow">
          <CircleButton handleButtonSubmit={backToHome} image={backArrow} />
        </div> */}
    </div>
  );
};

export default Navbar;
