import React from "react";
import "./bullet-points.css";

const BulletPoints = ({ arrowpoints = false, information }) => {
  return (
    <ul className={arrowpoints ? `arrow-points-main` : `bullet-points-main`}>
      <li>{information}</li>
    </ul>
  );
};

export default BulletPoints;
