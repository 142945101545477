import manali1 from "../../assets/exclusive-packages-assets/Manali/phone/manali1.webp";
import manali2 from "../../assets/exclusive-packages-assets/Manali/phone/manali2.webp";
// import manali3 from "../../assets/exclusive-packages-assets/Manali/phone/manali3.webp";
import manali4 from "../../assets/exclusive-packages-assets/Manali/phone/manali4.webp";
import manali5 from "../../assets/exclusive-packages-assets/Manali/phone/manali5.webp";
import manali6 from "../../assets/exclusive-packages-assets/Manali/phone/manali6.webp";
import manali7 from "../../assets/exclusive-packages-assets/Manali/phone/manali7.webp";

import manali_desk1 from "../../assets/exclusive-packages-assets/Manali/desktop/manali1.webp";
import manali_desk2 from "../../assets/exclusive-packages-assets/Manali/desktop/manali2.webp";
import manali_desk3 from "../../assets/exclusive-packages-assets/Manali/desktop/manali3.webp";
import manali_desk4 from "../../assets/exclusive-packages-assets/Manali/desktop/manali4.webp";
import manali_desk5 from "../../assets/exclusive-packages-assets/Manali/desktop/manali5.webp";
import manali_desk6 from "../../assets/exclusive-packages-assets/Manali/desktop/manali6.webp";

export const manaliPhone = [
  manali1,
  manali2,
  // manali3,
  manali4,
  manali5,
  manali6,
  manali7,
];
export const manaliDesktop = [
  manali_desk1,
  manali_desk2,
  manali_desk3,
  manali_desk4,
  manali_desk5,
  manali_desk6,
];
