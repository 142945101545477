import React, { useEffect, useState } from "react";
import "./footer.css";
import SocialLinks from "../../components/social-links/social-links";
import logo from "../../assets/email/logo.png";

const Footer = ({ whatsappMessage }) => {
  const [startTimer, setStartTimer] = useState(false);
  const [messageNumber, setMessageNumber] = useState(400);

  useEffect(() => {
    const handleScrolling = () => {
      if (
        window.scrollY + window.innerHeight >=
        document.body.scrollHeight - 500
      ) {
        setStartTimer(true);
      } else {
        setStartTimer(false);
        setMessageNumber(400);
      }
    };

    window.addEventListener("scroll", handleScrolling);

    return () => {
      window.removeEventListener("scroll", handleScrolling);
    };
  }, []);

  useEffect(() => {
    let interval;
    if (startTimer) {
      interval = setInterval(() => {
        setMessageNumber((prevNumber) => {
          if (prevNumber <= 22000) {
            return prevNumber + 50;
          } else {
            clearInterval(interval);
            return prevNumber;
          }
        });
      }, 1);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [startTimer]);

  return (
    <div className="footer-main">
      <div className="footer-main-sub">
        <div className="footer-main-text">
          <div className="footer-message-left">Trusted By</div>
          <div className="footer-message" id="footer-message-number">
            {messageNumber < 1000
              ? messageNumber
              : Math.floor(messageNumber / 1000) + "K"}
          </div>

          <div id="footer-message-submessage" className="footer-message">
            Travellers
          </div>
        </div>
        <div className="footer-message-bottom">
          <img src={logo} alt="app-logo" />
          <div className="footer-main-subtext">
            Weaving Your Dreams into Unforgettable Adventures
          </div>
          <div className="footer-main-social-link-container">
            <SocialLinks
              vertical={false}
              whatsappMessage={whatsappMessage}
              footer
            />
          </div>
          <div className="footer-main-email">Mojharroz@icloud.com</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
