import React from "react";
import Marquee from "react-fast-marquee";

import "./trusted-banner.css";
import { TRUSTED_BANNER_CONTENT } from "../../content/trusted-banner";

const TrustedBanner = ({ message, number, submessage }) => {
  const renderElements = [];

  TRUSTED_BANNER_CONTENT.map((con, index) => {
    return renderElements.push(
      <div className="trusted-banner-content-main" key={index}>
        {con.message && (
          <div className="trusted-banner-content">{con.message}</div>
        )}
        <div id="trusted-banner-content-id" className="trusted-banner-content">
          {con.number}
        </div>
        <div className="trusted-banner-content">{con.subMessage}</div>
      </div>
    );
  });

  return (
    <div className="trusted-banner-section-divider">
      <Marquee className="trusted-banner-marquee" speed={100}>
        {renderElements[0]}
        {renderElements[1]}
        {renderElements[2]}
        {renderElements[0]}
        {renderElements[2]}
        {renderElements[1]}
      </Marquee>
    </div>
  );
};

export default TrustedBanner;
