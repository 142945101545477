import React from "react";

//css
import "./about-us.css";

//content
import {
  ABOUT_US_TITLE,
  CONTENT_1,
  CONTENT_2,
  CONTENT_3,
} from "../../content/about-us";

// assets
import About_us from "../../assets/about-us/pic.png";
import About_us_desktop from "../../assets/about-us/desktop-image1.png";
import ContentBanner from "../../components/content-banner/content-banner";
import useWindowSize from "../../hooks/windowSize";

const AboutUs = () => {
  const { width, height } = useWindowSize();

  return (
    <div id="about-us">
      <div className="about-us-main">
        {width >= 850 ? (
          <div
            style={{
              width: "100%",
              // border: "2px solid red",
              height: "15em",
              position: "relative",
            }}
          >
            <img
              className="about_us_desktop"
              src={About_us_desktop}
              alt="about us"
            />

            <div
              style={{
                position: "absolute",
                top: "5em",
                width: "80%",
                marginLeft: "5%",
                color: "white",
                fontSize: "20px",
                fontFamily: "Roboto, sans-serif",
                fontWeight: "400",
                fontStyle: "normal",
              }}
            >
              PEDDLERS OF PARVATI: is dedicated to providing exhilarating and
              transformative travel experiences for adventurers of all levels.
              We believe in exploring the world responsibly while fostering a
              deep appreciation for diverse cultures and environments
            </div>
          </div>
        ) : (
          <img src={About_us} alt="About us" />
        )}
        <div className="about-us-title">{ABOUT_US_TITLE}</div>
      </div>
      <div className="about-us-content-main">
        {width >= 850 ? (
          <ContentBanner
            backgroundColor="none"
            content={CONTENT_1}
            fontColor="black"
            boxShadow="none"
            visible={false}
          />
        ) : (
          <ContentBanner
            backgroundColor=""
            content={CONTENT_1}
            fontColor="white"
            boxShadow="none"
            visible={true}
          />
        )}
        {width >= 850 ? (
          <div
            style={{
              // border: "2px solid red",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              padding: "3em",
            }}
          >
            <ContentBanner
              backgroundColor="#d3af2a"
              content={CONTENT_2}
              visible={true}
            />
            <ContentBanner
              backgroundColor="#d3af2a"
              content={CONTENT_3}
              visible={true}
            />
          </div>
        ) : (
          <>
            <ContentBanner
              backgroundColor="#d3af2a"
              content={CONTENT_2}
              visible={true}
            />
            <ContentBanner
              backgroundColor="#d3af2a"
              content={CONTENT_3}
              visible={true}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AboutUs;
