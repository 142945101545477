import React from "react";
import "./back-button.css";

import backArrow from "../../assets/controls/la.png";
import frontArrow from "../../assets/controls/ra.png";

const BackButton = ({ backArrowMark, handleBackButton }) => {
  return (
    <div onClick={() => handleBackButton()} className="button-container-main">
      <img src={backArrowMark ? backArrow : frontArrow} />
    </div>
  );
};

export default BackButton;
